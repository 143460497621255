import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from "react-router-dom";
import { FaLocationArrow, FaMobileAlt, FaEnvelope } from "react-icons/fa";

import PrivacyPolicy from './privacy_policy';

import About from './About';

import TermsConditions from './terms_conditions';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


import './scss/footer.css';

import './scss/newslatter.css';





import ChatIcon from '@mui/icons-material/Chat';

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto'

});

export default function BottomAppBar() {

  const navigate = useNavigate();


  function sendtoSupport() {

    window.location.replace("https://api.whatsapp.com/send?phone=917300042331&test=https://saudebazi.com/");


  }


  return (
    <React.Fragment>


<div className='container-fluid saksh-dark border-top   saksh-footer' >
      <div className='container py-5'>

        <div className='row'>

           
          <div className="col "><div className="title  fw-bold pb-2">
            Contact
          </div>
            <div className="c-item">
            
              <div className="text "> Saudebazi Group </div></div>
            <div className="c-item">
             
              <div className="text">+917300042331</div></div>
            <div className="c-item">
            
              <div className="text">contact@saudebazi.com
              </div>
            </div>
          </div>
          <div className="col">

          </div>
          <div className="col saksh-font-gibson">




            <div className="title  fw-bold pb-2">Pages</div>
            <span className="text "><Link className="text footermenu" to="/terms_conditions">Terms and conditions</Link></span> <br />
            <span className="text footermenu"><Link className="text footermenu" to="/privacy_policy">Privacy Policy</Link></span>








          </div>




        </div>




      </div>
 



 
        <div className="b  text-center p py-2">
          <div className="bottom-bar-content">
            <div className="text saksh-font-gibson">
              2024 @Saudebazi Group. All rights reserved.
            </div>
            {/* <img src={Payment} alt="" /> */}
          </div>
        </div> 

        </div> 

    </React.Fragment>
  );
}





