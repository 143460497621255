
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";



export const Logout = () => {



    const navigate = useNavigate();
    const handlelogout = () => {

        localStorage.clear();
        navigate("/login");



    }


    useEffect(() => {
        handlelogout();
    }, []);




    return (

        <>


            <button onClick={handlelogout} >

                Log Out
            </button>
        </>

    )
}