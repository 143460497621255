
import { Component } from 'react';

class Subscription extends Component {



  render() {
    return <> Subscription details coming soon...</>;
  }
}

export default Subscription;