

import {

    useQuery,
} from '@tanstack/react-query'



import API from "./API";
export const C404 = () => {



    const { isLoading, error, data } = useQuery({
        queryKey: ['repoData'],
        queryFn: async () => await API.get("commodities"),



    })

    console.log(data);


    if (isLoading) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    return (
        <div>

            <table className="table  table-auto  ">


                <tbody>

                    {data.map((item, index) => (


                        <tr key={index}>
                            <td>   {item.label} </td>

                            <td >   Seller deals available:-{item.deal_count}
                            </td>
                            <td>



                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    )
}