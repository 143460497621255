import React, { useState } from "react"
import API from "./API";
import { useNavigate } from "react-router-dom";


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';


export const SendOtp = () => {


    const [otp, set_otp] = useState("");
    const [message, setmessage] = useState("");

    // const auth = useAuth();

    const navigate = useNavigate();


    const [open, setOpen] = React.useState(false);

    const handlogin = async () => {


        if (otp.length !== 6) {
            setOpen(true);
            return "";
        }





        try {

            const reqdata = {

                email: localStorage.getItem('query'),
                otp: otp
            };


            const res = await API.post("login", reqdata);



            const data = res.data;





            setmessage(data.message);
            setOpen(true);

            if (data.status === true) {
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(data.user));


                localStorage.removeItem('login_status');
                localStorage.setItem('login_status', data.status);

                localStorage.removeItem('subscription_id');
                localStorage.setItem('subscription_id', data.user.subscription_id);


                localStorage.removeItem('token');
                localStorage.setItem('token', data.token);

                //   if (data.user.mobile == null)
                navigate("/update_profile")
                //   else
                //   navigate("/")

            }

        }
        catch (error) {

            setmessage("Some technical error please try after some time.");
            setOpen(true);
        }

    }





    return (

        <>    <div className="container-fluid  text-center m saksh-secion ">

            <div className="container  py-5  animate__animated animate__fadeInUp">


                <div className="row saksh-box-text-header">


                    <div className="col ">

                        <h3> Verify OTP </h3>

                        <div className="mb-3">





                            <TextField
                                margin="normal"
                                required
                                fullWidth="true"
                                id="otp"
                                label="OTP"
                                name="otp"
                                autoComplete="otp"

                                onChange={(e) => set_otp(e.target.value)}

                                autoFocus
                            />



                        </div>
                        <div className="mb-3">


                            <Button       fullWidth="true" onClick={handlogin} size="large" variant="outlined">Login...</Button>
                            <br />
                            <p><small>By using our platform you agree with our Terms and Conditions and Privacy Policy </small></p>
                            <Snackbar
                                open={open}
                                autoHideDuration={2000}
                                onClose={() => setOpen(false)}
                                message={message}

                            />

                        </div>  </div>  </div>  </div>  </div>

        </>


    )


}


