


import { Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';



//import Home from './components/Home';




import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



import NoteState from "./components/NoteState";
import { Logout } from './components/logout';
import BottomAppBar from './components/footer';
import { Helmet } from "react-helmet";

import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';

import DrawerAppBar from "./components/Drawer";
import { Description } from '@mui/icons-material';






import PrivacyPolicy from './components/privacy_policy';
import TermsConditions from './components/terms_conditions';



import Subscription from './components/Subscription';

import About from './components/About';

import { Profile } from './components/Profile';

import { Login } from './components/Login';
import { C404 } from './components/404';
import { UpdateProfile } from './components/updateProfile';

 

import { SendOtp } from './components/sendOTP';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

//import CommodityDetails from './components/CommodityDetails';
//import CommodityGradeBlock from './components/CommodityGradeBlock';


const LazyHome = React.lazy(() => import('./components/Home'));
const LazyMarketWatch = React.lazy(() => import('./components/MarketWatch'));

const LazyCommodityDetails = React.lazy(() => import('./components/CommodityDetails'));
const LazyCommodityGradeBlock = React.lazy(() => import('./components/CommodityGradeBlock'));





function App() {


  const queryClient = new QueryClient()

  return (



    <QueryClientProvider client={queryClient}>


      <NoteState>

        <DrawerAppBar />

      



        <Routes>



          <Route path="/logout" element={<Logout />}></Route>
          <Route path="/C404" element={<C404 />}></Route>


          <Route path="/privacy_policy" element={<PrivacyPolicy />}></Route>


          <Route path="/terms_conditions" element={<TermsConditions />}></Route>


 

          <Route path="/MarketWatch" element={
            <React.Suspense fallback="Loading... please wait ">

              <LazyMarketWatch />

            </React.Suspense>

          }></Route>



          <Route path="/Subscription" element={<Subscription />}></Route>

          <Route path="/about" element={<About />}></Route>
          <Route path="/Profile" element={<Profile />}></Route>

          <Route path="/update_profile" element={<UpdateProfile />}></Route>


          <Route path="/commodity/:label" element={
            <React.Suspense fallback="Loading... please wait ">

              <LazyCommodityDetails />

            </React.Suspense>

          }>



            <Route path=":grade" element={
              <React.Suspense fallback="Loading... please wait ">

                <LazyCommodityGradeBlock />

              </React.Suspense>

            }></Route>


          </Route>


          <Route path="/" element={
            <React.Suspense fallback="Loading... please wait ">

              <LazyHome />

            </React.Suspense>

          }></Route>

<Route path="/" element={
            <React.Suspense fallback="Loading... please wait ">

              <LazyHome />

            </React.Suspense>

          }></Route>


          <Route path="/login" element={<Login />}></Route>
          <Route path="/SendOtp" element={<SendOtp />}></Route>


        </Routes>







        <BottomAppBar />


      </NoteState>

    </QueryClientProvider>




  );
}

export default App;






