import React, { useContext, useState } from "react"

import { useNavigate } from "react-router-dom";


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';

import API from "./API";


export const Login = () => {

    const [query, setQuery] = useState("");
    const [message, setmessage] = useState("");


    const [open, setOpen] = React.useState(false);


    const navigate = useNavigate();

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }


    const handlogin = async () => {


        if (query.length > 10)
            if (!isValidEmail(query)) {
                setmessage("Please provide correct email.");
                setOpen(true);
                return;
            }


        const reqdata = {

            email: query
        };


        const res = await API.post("sendOTP", reqdata, {
            headers: {
                'content-type': 'text/json'
            }
        });

        const data = res.data;
        setmessage(data.message);
        setOpen(true);

        if (data.status === true) {
            localStorage.removeItem('query');
            localStorage.setItem('query', query);


            navigate("/SendOtp")

        }

    }





    return (

        <>




            <div className="container-fluid dark  saksh-dark saksh-secion ">


                <div className="container  py-5  ">


                    <div className="row saksh-box-text-header">


                        <div className="col">


                            <h2 className="display-5 mt-5">

                                Login
                            </h2>

                            <div className="mb-3">





                                <TextField
                                    margin="normal"
                                    required
                                  
                                    fullWidth="true"

                                    label="Email/Whatsapp Number"



                                    onChange={(e) => setQuery(e.target.value)}


                                    autoFocus
                                />



                            </div>
                            <div className="mb-3">

                                <Button      fullWidth="true"  onClick={handlogin} size="large" variant="outlined">Send OTP</Button>

                                <Snackbar
                                    open={open}
                                    autoHideDuration={2000}
                                    onClose={() => setOpen(false)}
                                    message={message}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>


    )


}


