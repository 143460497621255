
import { Component } from 'react';


import '../about.css';


class About extends Component {

  

  render() {
    return <>


      <div className="container-fluid  text-center  saksh-dark  saksh-secion ">

        <div className="container  py-5  animate__animated animate__fadeInUp">


          <div className="row saksh-box-text-header">

            <h2 className=" block-title   animate__animated animate__fadeInUp ">


              About Us
            </h2>
            <div className="col-md-12   animate__animated animate__fadeInUp">


              <p>

                Saudebazi Group is a technology first network of verified suppliers based out of India. We help businesses
                and farmer collectives sell anytime, anywhere while offering high levels of supply assurance.
                <br /><br />

                Our team has over 100+ years of experience in food supply chains, technology and financial services industries.


              </p>
            </div></div></div></div>
    </>;
  }
}

export default About;