import * as React from 'react';
import { useNavigate } from "react-router-dom";

import { useContext, useState, useEffect } from 'react';

import noteContext from './NoteContext';


import API from "./API";


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

        },
    },
};




export const UpdateProfile = () => {

    const checked = true;
    const Unchecked = false;

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);


    const [message, setmessage] = useState("");

    const [new_commodity, setcommodity] = useState([]);



    const [mobile, setMobile] = useState("");

    const [company_name, setCompanyName] = useState("");

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [open, setOpen] = useState(false);

    const [Commodities, setCommodities] = useState([]);

    const [new_commodities, setNCommodities] = useState([]);

    const [MasterCommodities, setMasterCommodities] = useState([]);





    const data = useContext(noteContext);



    const [user, setUser] = useState(data.user);



    const navigate = useNavigate();

    let token = data.token;


    const handleCommodityChange = (event) => {
        const {
            target: { value },
        } = event;
        setNCommodities(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };




    const fetchAPIData = async () => {

        try {
            const res = await API.get("commodities");
            setMasterCommodities(res.data);




        }
        catch (error) {
            console.log(error);

        }


    };




    const loadform = async () => {
        const res = await API.get("me", {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });
        const dt = res.data;

        if (dt.user.company_name) {

            let company_name_=dt.user.company_name.length;



            if( company_name_ > 1)
            navigate("/")

            
            setCompanyName(dt.user.company_name);

        }

        if (dt.user.name) {
            setName(dt.user.name);


        }

        if (dt.user.email) {
            setEmail(dt.user.email);


        }

        if (dt.user.mobile) {

            setMobile(dt.user.mobile);

        }

      

        if (dt.user.commodities) {

            if (dt.user.commodities != null)
                setCommodities(JSON.parse(dt.user.commodities));


        }
        setUser(dt.user);

        console.log(dt.user);

    };



    const handlesubmit = async () => {


        const reqdata = {

            mobile: mobile,
            email: email,
            company_name: company_name,
            commodities: new_commodities,
            name: name
        };

        /*
                if (mobile.length != 10) {
                    setmessage("Please provide correct mobile.");
                    setOpen(true);
                    return;
                }
        
        
                if (name.length < 1) {
                    setmessage("Please provide correct mobile.");
                    setOpen(true);
                    return;
                }
        */

        const res = await API.post("updateprofile", reqdata, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        const dt = res.data;

        setmessage(dt.message);


        setOpen(true);

        navigate("/");

    }




    useEffect(() => {


        fetchAPIData();

        loadform();


    }, []);












    return (

        <>

            <div className="container-fluid  text-center  saksh-dark  saksh-secion ">

                <div className="container  py-5  animate__animated animate__fadeInUp">


                    <div className="row saksh-box-text-header">


                        <div className="col-md-12">
                            <div className="mb-3">




                                Name
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth

                                    value={name}




                                    onChange={(e) => setName(e.target.value)}


                                    autoFocus
                                />



                            </div>

                            <div className="mb-3">




                                Company  Name
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth

                                    value={company_name}
                                    onChange={(e) => setCompanyName(e.target.value)}


                                    autoFocus
                                />


                            </div>
                            <div className="mb-3">




                                Mobile
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth

                                    value={mobile}




                                    onChange={(e) => setMobile(e.target.value)}



                                />



                            </div>


                            <div className="mb-3">




                                Email
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth

                                    value={email}

                                    onChange={(e) => setEmail(e.target.value)}



                                />



                            </div>


                            <div className="mb-3">

                                Commodities


                                <Select
                                    labelId="commodities-label"
                                    id="commodities-label-control"
                                    multiple
                                    fullWidth
                                    value={new_commodities}
                                    onChange={handleCommodityChange}
                                    input={<OutlinedInput label="Commodity" />}
                                    MenuProps={MenuProps}
                                >
                                    {MasterCommodities.map((item) => (
                                        <MenuItem
                                            key={item.label}
                                            value={item.id}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>








                                <Snackbar
                                    open={open}
                                    autoHideDuration={2000}
                                    onClose={() => setOpen(false)}
                                    message={message}

                                />
                            </div>

                            <div className="mb-3">

                                <Button fullWidth onClick={handlesubmit} size="large" variant="outlined">Update</Button>

                            </div>


                        </div>

                    </div>

                </div>

            </div>




        </>

    )
}