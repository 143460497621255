import axios from "axios"


const API =axios.create({


    
    baseURL : "https://mapi.sakshamapp.com/api/"


});


export default API;


